import "@babel/polyfill";
import "picturefill";
import objectFitImages from "object-fit-images";
import "intersection-observer";

/* Modules
==================== */
// ドロワー
import drawer from "./modules/drawer";
drawer();

// スライダー
import swiper from "./modules/swiper";
swiper();

// cvBtn
import cvBtn from "./modules/cvBtn";
cvBtn();

// スムーススクロール
import smoothScroll from "./modules/smoothScroll";
smoothScroll();

// アコーディオン
import accordion from "./modules/accordion";
accordion();

// スクロールイベント
import scrollEvent from "./modules/scrollEvent";
scrollEvent();

// セレクトボタン中央寄せ
// import select from "./modules/select";
// select();


/* Script
==================== */
objectFitImages();

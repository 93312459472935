export default function menu() {

  document.addEventListener("DOMContentLoaded", () => {

    if (window.matchMedia('(min-width: 1081px)').matches) {

      $(".hvBtn").hover(function () {
        $(this).children('.sublist').fadeIn(30);
      }, function () {
        $(this).children('.sublist').fadeOut(30);
      });

    } else {
      const acdBtns = [...document.querySelectorAll('.acdBtn')];

      function test(event) {
        const sublist = event.currentTarget.nextElementSibling;
        event.currentTarget.classList.toggle('is-active');
        sublist.classList.toggle('is-open');
      }

      acdBtns.forEach((acdBtn) => {
        acdBtn.addEventListener('click', test, false);
      });

      //  reservation
      var state = false;
      var scrollpos;
      $(".hvBtn").on("click", function () {
        if (state == false) {
          document.documentElement.style.overflow = 'hidden';
          disableBodyScroll(document.querySelector('.reservation-modal'));
          $(this).children('.reservation-modal').addClass('open');
          state = true;
        } else {
          document.documentElement.style.overflow = '';
          enableBodyScroll(document.querySelector('.reservation-modal'));
          $(this).children('.reservation-modal').removeClass('open');
          state = false;
        }
      });

      $('.reservation-modal__btn').on('click', function () {
        $('.hvBtn').click();
      });
    }
  });
}

export default function menu(smoothScroll) {

    // スムーススクロール
    $('a[href^="#"]').click(function() {
        // スクロールの速度
        var speed = 500; // ミリ秒
        var href = $(this).attr("href");
        var target = $(href == "#" || href == "" ? "html" : href);
        var position = target.offset().top - 0; //固定メニューの分ずらす

        $("body, html").animate(
          {
            scrollTop: position
          },
          speed,
          "swing"
        );
        return false;
      });
}
